<template>
  <div>
    <edit-term-modal
      :term="editTerm"
      v-if="addEditTerm"
      @close="
        addEditTerm = !addEditTerm;
        editTerm = null;
      "
      @create="newTerm"
      @update="updateTerm"
    />
    <div class="flex justify-between items-center mb-5">
      <div class="header-text pb-3" data-cy="header-admin-milestones">
        Terms
      </div>
      <div>
        <Button text="Start a New Term" @click="addEditTerm = true" />
      </div>
    </div>
    <div class="box-style">
      <loading-spinner v-if="loading" />
      <dynamic-table
        :records="terms"
        :fields="tableFields"
        striped
        pagination
        hover
        :pagination-total-items="total"
        :pagination-page="page"
        :pagination-page-size="pageSize"
        v-else
      >
        <template #cell(start_date)="{ item }">
          {{ TableDateFormat(item.start_date) }}
        </template>
        <template #cell(end_date)="{ item }">
          {{ TableDateFormat(item.end_date) }}
        </template>
        <template #cell(edit)="{ item }">
          <a
            aria-label="edit term and milestones"
            @click="
              editTerm = item;
              addEditTerm = true;
            "
          >
            <font-awesome-icon icon="edit" />
          </a>
        </template>
      </dynamic-table>
    </div>
  </div>
</template>

<script>
import { TableDateFormat } from "@/composables/date_utils";
import { get, post, put } from "@/composables/httpUtil";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import Button from "@/components/reusable-components/base-level-components/Button";
import EditTermModal from "@/views/admin/sys-administration/settings/EditTermModal";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";

export default {
  name: "AdminMilestones",
  components: { LoadingSpinner, EditTermModal, DynamicTable, Button },
  data() {
    return {
      terms: [],
      pages: 0,
      page: 0,
      pageSize: 0,
      total: 0,
      tableFields: [
        "name",
        "start_date",
        "end_date",
        { name: "edit", display: " " },
      ],

      loading: false,
      addEditTerm: false,
      editTerm: null,
    };
  },
  mounted() {
    this.loading = true;
    this.fetchTerms().then(() => (this.loading = false));
  },
  methods: {
    get,
    post,
    put,
    TableDateFormat,
    fetchTerms() {
      return this.get("admin/terms").then((terms) => {
        this.terms = terms.data;
        this.pages = terms.total_pages;
        this.page = terms.page_number;
        this.pageSize = terms.page_size;
        this.total = terms.count;
      });
    },
    updateTerm(term) {
      this.put(`admin/terms/${term.term_id}`, term).then((t1) => {
        const idx = this.terms.findIndex((t2) => t2.term_id === t1.term_id);
        this.terms[idx] = t1;
      });
      this.addEditTerm = !this.addEditTerm;
    },
    newTerm(term) {
      delete term.term_id;
      delete term.milestones.milestone_id;
      this.post("admin/terms", term).then(
        (t) => (this.terms = [t, ...this.terms])
      );
      this.addEditTerm = !this.addEditTerm;
    },
  },
};
</script>

<style scoped></style>

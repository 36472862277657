<template>
  <div
    :class="{
      'grid grid-cols-1 absolute z-[75]': true,
      'top-0 right-0 w-full h-full py-20 bg-dark-gray bg-opacity-40 transition duration-150 ease-in-out':
        blocking,
    }"
  >
    <div
      :class="{ 'absolute w-full h-full': true }"
      @click="this.$emit('close', index)"
      v-if="blocking"
    >
      <!--      Receives the click-->
    </div>
    <div
      :class="{
        'relative mx-auto box-style border border-light-gray p-3': true,
        'z-[100]': blocking,
        'w-1/2 h-fit': medium,
        'w-5/6': large,
        absolute: !locked,
        'overscroll-y-contain overflow-y-auto': locked,
      }"
    >
      <div
        ref="popupHeader"
        aria-labelledby="popup displayed"
        class="flex justify-between items-center"
      >
        <div>
          <slot name="header">
            <div class="thirdhead-text">
              {{ label }}
            </div>
          </slot>
        </div>
        <div class="px-3">
          <button
            aria-label="close"
            class="text-2xl font-bold text-dark-purple cursor-pointer"
            @click="this.$emit('close', index)"
          >
            <font-awesome-icon class="text-sm" icon="times" />
          </button>
        </div>
      </div>
      <div class="my-3">
        <slot name="content"> </slot>
      </div>
      <slot name="footer">
        <div class="flex items-center justify-end" v-if="!no_submit">
          <button
            class="inline-block py-1 px-2.5 bg-purple rounded-full text-white text-sm hover:bg-dark-purple"
            @click="$emit('close')"
            v-if="show_cancel"
          >
            {{ cancel_btn_text }}
          </button>
          <button
            class="inline-block py-1 px-2.5 border border-purple rounded-full text-purple text-sm ml-2 hover:bg-purple hover:text-white"
            @click="this.$emit('submit', index)"
            data-cy="modal-submit"
            :disabled="disabled"
          >
            {{ submit_btn_text }}
          </button>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  emits: ["close", "submit"],
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    submit_btn_text: {
      type: String,
      required: false,
      default: "Submit",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    no_submit: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_cancel: {
      type: Boolean,
      default: false,
    },
    cancel_btn_text: {
      type: String,
      required: false,
      default: "Cancel",
    },
    blocking: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    //focus added for accessability
    this.$refs.popupHeader.focus();
  },
};
</script>

<style scoped></style>

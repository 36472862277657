<template>
  <div class="grid grid-cols-2">
    <div class="text-left pt-2">
      <template v-if="this.totalItems">{{this.totalItems}} Records</template>
    </div>
    <div class="text-right">
  
    <button :disabled="this.page === 1" @click="pageChanged(-1)">
      <font-awesome-icon
        class="text-purple"
        icon="arrow-left"
        aria-label="page left"
      />
    </button>

    <label class="px-4" v-if="totalPages">
      Page
      <input
        type="number"
        aria-label="jump to page"
        :value="this.page"
        class="border-steel-gray rounded h-8 w-16"
        ref="jump"
        @change="pageJumped(this.$refs.jump.value)"
      />
      of {{ totalPages }}
    </label>
    <label class="px-4" v-else> Page {{ this.page }}</label>
    <button @click="pageChanged(1)" :disabled="this.page === this.totalPages">
      <font-awesome-icon
        class="text-purple"
        icon="arrow-right"
        aria-label="page right"
      />
    </button>
      </div>
  </div>
</template>

<script>
//-------------------------------------------------------------------------------------------------------
// Purpose: Provide a reusable pagination feature for server side pagination
// Inputs:
//      itemsPerPage - Number - number of items displayed in the table
//      totalItems   - Number - number of items in the collection
//      currentPage  - Number - current page being viewed
// Outputs:
//      pageChanged - Object - Outputs the new page number you wish to switch too and page size
//-------------------------------------------------------------------------------------------------------

export default {
  name: "Pagination",
  components: {},
  created() {
    this.page = this.currentPage;
    this.setTotalPages();
  },
  watch: {
    itemsPerPage() {
      this.setTotalPages();
    },
    totalItems() {
      this.setTotalPages();
    },
    currentPage() {
      this.page = this.currentPage;
    },
  },
  data() {
    return {
      page: null,
      totalPages: null,
    };
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 25,
    },
    totalItems: {
      type: Number,
      default: -1,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    pageChanged(newPage) {
      const paginationEmit = {
        currentPage: this.page,
        newPage: (this.page += newPage),
        itemsPerPage: this.itemsPerPage,
      };
      this.$emit("pageChanged", paginationEmit);
      // this.page += newPage;
    },
    pageJumped(requestedPage) {
      const newPage = parseInt(requestedPage);
      if (newPage <= 0 || newPage > this.totalPages) {
        this.pageChanged(0); //not changing page because invalid input
      } else {
        const paginationEmit = {
          currentPage: this.page,
          newPage: newPage,
          itemsPerPage: this.itemsPerPage,
        };
        this.$emit("pageChanged", paginationEmit);
        this.page = newPage;
      }
    },
    setTotalPages() {
      //if we know the total number of items, set the total number of pages
      if (this.totalItems !== -1) {
        let pages = parseInt(this.totalItems / this.itemsPerPage);
        if (this.totalItems % this.itemsPerPage !== 0) {
          pages++;
        }

        this.totalPages = pages;
      }
    },
  },
};
</script>

<style scoped>
</style>
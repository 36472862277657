<template>
  <button
    @click="sortData"
    class="px-1"
    aria-label="column sorting option for table"
  >
    <font-awesome-icon
      icon="sort"
      v-if="this.tempColumnSet[this.sortByColumn] === ''"
    />
    <font-awesome-icon
      icon="sort-up"
      v-else-if="this.tempColumnSet[this.sortByColumn] === 'DESC'"
    />
    <font-awesome-icon icon="sort-down" v-else />
  </button>
</template>

<script>
//---------------------------------------------------------------------------------------------------------------------------------------------
// Purpose: Provide a reusable component to handle column sorting for html tables
// Inputs :
//      sortType   - Sort type is used to determine the datatype stored in the column which controls the type of sort applied
//                 - Type: string, Required: false - this field will be defaulted as a STRING search
//      sortData   - data that is needed to be sorted. This is a pointer to the stored data which allows us to sort the data in the parent component
//                 - Type: Array<Object>, Required true
//      sortColumn - this field is the column we wish to sort by in the table
//                 - Type: string, Required: true
//      columnSet  - this field is used to connect all sortable fields in a single table so we can determine which sort is currently applied
//                 - Type: Object, Required: Only when using more than one sortable column per table
//---------------------------------------------------------------------------------------------------------------------------------------------

export default {
  name: "ColumnSorting",
  components: {},
  created() {
    this.tempTableData = this.tableData;
    if (this.columnSet !== undefined) {
      this.tempColumnSet = this.columnSet;
    }

    this.tempColumnSet[this.sortByColumn] = "";
  },
  data() {
    return {
      tempTableData: [],
      tempColumnSet: {},
    };
  },
  props: {
    sortType: {
      //Possible Inputs: STRING, DATE, and NUMBER
      type: String,
      default: "STRING", //different sorting required for strings, numbers, and dates
    },
    tableData: {
      type: Array,
      required: true,
    },
    sortByColumn: {
      type: String,
      required: true,
    },
    columnSet: {
      type: Object,
    },
  },
  methods: {
    sortData() {
      //The purpose of this method is to reset any column filters already applied. This is done so that we can visually see both sort buttons displayed
      if (this.tempColumnSet[this.sortByColumn] === "") {
        this.setColumFilters();
      }

      switch (this.sortType) {
        case "STRING":
          if (this.tempColumnSet[this.sortByColumn] !== "ASC") {
            this.tempColumnSet[this.sortByColumn] = "ASC";
            this.tempTableData = this.tempTableData.sort(this.stringCompareAsc);
          } else {
            this.tempColumnSet[this.sortByColumn] = "DESC";
            this.tempTableData = this.tempTableData.sort(
              this.stringCompareDesc
            );
          }
          break;
        case "NUMBER":
          if (this.tempColumnSet[this.sortByColumn] !== "ASC") {
            this.tempColumnSet[this.sortByColumn] = "ASC";
            this.tempTableData = this.tempTableData.sort(this.numberCompareAsc);
          } else {
            this.tempColumnSet[this.sortByColumn] = "DESC";
            this.tempTableData = this.tempTableData.sort(
              this.numberCompareDesc
            );
          }
          break;

        case "DATE":
          if (this.tempColumnSet[this.sortByColumn] !== "ASC") {
            this.tempColumnSet[this.sortByColumn] = "ASC";
            this.tempTableData = this.tempTableData.sort(this.dateCompareAsc);
          } else {
            this.tempColumnSet[this.sortByColumn] = "DESC";
            this.tempTableData = this.tempTableData.sort(this.dateCompareDesc);
          }
          break;
      }
    },
    setColumFilters() {
      const keys = Object.keys(this.tempColumnSet);

      keys.forEach((columnKey) => {
        if (this.sortByColumn !== columnKey) {
          this.tempColumnSet[columnKey] = "";
        }
      });
    },
    stringCompareAsc(a, b) {
      const itemA = a[this.sortByColumn].toUpperCase();
      const itemB = b[this.sortByColumn].toUpperCase();

      let comparison = 0;
      if (itemA > itemB) {
        comparison = 1;
      } else {
        comparison = -1;
      }

      return comparison;
    },
    stringCompareDesc(a, b) {
      const itemA = a[this.sortByColumn].toUpperCase();
      const itemB = b[this.sortByColumn].toUpperCase();

      let comparison = 0;
      if (itemA > itemB) {
        comparison = -1;
      } else {
        comparison = 1;
      }

      return comparison;
    },
    numberCompareAsc(a, b) {
      const itemA = a[this.sortByColumn];
      const itemB = b[this.sortByColumn];

      let comparison = 0;
      if (Number(itemA) > Number(itemB)) {
        comparison = 1;
      } else {
        comparison = -1;
      }

      return comparison;
    },
    numberCompareDesc(a, b) {
      const itemA = a[this.sortByColumn];
      const itemB = b[this.sortByColumn];

      let comparison = 0;
      if (Number(itemA) > Number(itemB)) {
        comparison = -1;
      } else {
        comparison = 1;
      }

      return comparison;
    },
    dateCompareAsc(a, b) {
      const itemA = a[this.sortByColumn];
      const itemB = b[this.sortByColumn];

      let comparison = 0;
      if (new Date(itemA) > new Date(itemB)) {
        comparison = 1;
      } else {
        comparison = -1;
      }

      return comparison;
    },
    dateCompareDesc(a, b) {
      const itemA = a[this.sortByColumn];
      const itemB = b[this.sortByColumn];

      let comparison = 0;
      if (new Date(itemA) > new Date(itemB)) {
        comparison = -1;
      } else {
        comparison = 1;
      }

      return comparison;
    },
  },
};
</script>

<style scoped>
</style>
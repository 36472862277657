<template>
  <table id="dynamic-table" class="text-dark-gray w-full" v-if="records?.length > 0">
    <thead class="border-b border-cool-gray">
      <tr>
        <th
          v-for="(header, key) in headers"
          :key="key"
          :class="header.class ? header.class : 'text-left'"
        >
          {{ header.display }}
          <column-sorting
            v-if="header.sortable"
            :columnSet="{}"
            :tableData="this.records"
            :sortByColumn="header.name"
            :sortType="header.sortType"
          />
        </th>
      </tr>
    </thead>
    <tbody class="text-sm">
      <tr
        v-for="(entry, key) in records"
        :key="key"
        :class="{
          'even:bg-light-purple/30': striped,
          'hover:bg-light-purple/75': hover,
        }"
      >
        <td v-if="selectable">
          <input
            :type="select === 'MANY' ? 'checkbox' : 'radio'"
            :name="select === 'ONE' ? 'table_select' : `table_select_${key}`"
            :class="select === 'MANY' ? 'rounded' : 'rounded-full'"
            :data-cy="idKey ? `id_${entry[idKey]}` : `id_${key}`"
            @change="$emit('toggleSelected', entry)"
          />
        </td>
        <td
          v-for="(header, key) in selectable ? headers.slice(1) : headers"
          :key="key"
          :class="header.class ? header.class : 'text-left'"
        >
          <slot
            :name="`cell(${header.name})`"
            :value="entry[header.name]"
            :item="entry"
          >
            {{ get_value(entry, header.name) }}
          </slot>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr v-if="pagination">
        <td
          :colspan="selectable ? fields?.length + 1 : fields?.length"
          class="text-right"
        >
          <Pagination
            @pageChanged="paginationPageChanged($event)"
            :itemsPerPage="paginationPageSize"
            :totalItems="paginationTotalItems"
            :currentPage="paginationPage"
          />
        </td>
      </tr>
    </tfoot>
  </table>

  <div v-else>{{ noDataMsg }}</div>
</template>

<script>
import ColumnSorting from "@/components/reusable-components/ColumnSorting";
import Pagination from "@/components/reusable-components/Pagination";
export default {
  name: "DynamicTable",
  components: { ColumnSorting, Pagination },
  props: {
    records: {
      type: [Array, Object],
      required: true,
    },
    fields: {
      type: Array,
      required: false,
    },
    noDataMsg: {
      type: String,
      required: false,
    },
    selectable: {
      type: [Boolean, Number],
      required: false,
      default: false,
    },
    select: {
      type: String,
      required: false,
      default: "MANY",
      validator(value) {
        return ["MANY", "ONE"].includes(value.toUpperCase());
      },
    },
    striped: {
      type: Boolean,
      required: false,
      default: false,
    },
    hover: {
      type: Boolean,
      required: false,
      default: false,
    },
    idKey: {
      type: String,
      required: false
    },
    pagination: {
      type: Boolean,
      required: false,
      default: false,
    },
    paginationTotalItems: {
      type: Number,
      required: false,
    },
    paginationPageSize: {
      type: Number,
      required: false,
      default: 25,
    },
    paginationPage: {
      type: Number,
      required: false,
      default: 1,
    },
    idName: {
      type: String,
      required: false,
      default: null,
    },
    rowPath: {
      type: String,
      required: false,
      default: null,
    },
    fromRoute: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => {
    return {
      headers: [],
    };
  },
  created() {
    this.headers = [
      ...(this.fields
        ? this.fields.map(this.format_header)
        : Object.keys(this.records).map(this.format_header)),
    ];
    if (this.selectable) {
      this.headers = [{ name: " ", display: " ", class: " " }, ...this.headers];
    }
  },
  methods: {
    format_header(header) {
      switch (typeof header) {
        case "string":
          return {
            name: header,
            display: header
              .split("_")
              .map(
                (word) => `${word.charAt(0).toUpperCase()}${word.substring(1)}`
              )
              .join(" "),
            sortable: false,
            overrideClass: false,
          };
        case "object":
          return {
            ...header,
            display: header.display
              ? header.display
              : header.name
                  .split("_")
                  .map(
                    (word) =>
                      `${word.charAt(0).toUpperCase()}${word.substring(1)}`
                  )
                  .join(" "),
            overrideClass: !!header.class,
          };
        default:
          throw Error("malformed field header in DynamicTable");
      }
    },
    get_value(entry, field) {
      if (entry) {
        if (field.includes(".")) {
          const field_path = field.split(".");
          return this.get_value(
            entry[field_path[0]],
            field_path.slice(1).join(".")
          );
        }
        return entry[field];
      }
      return null;
    },
    paginationPageChanged(paginationEmit) {
      this.$emit("pageChanged", paginationEmit);
    },
  },
};
</script>

<style scoped></style>

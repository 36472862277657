<template>
  <modal
    @close="$emit('close')"
    @submit="formatTerm"
    :disabled="err_msgs.length !== 0"
    :label="`${term_id ? 'Edit' : 'Add New'} Term`"
    blocking
  >
    <template #content>
      <error-banner
        class="mb-5"
        :warn="validating"
        :error="err_msgs.length > 0"
        :success="err_msgs.length === 0"
        :messages="err_msgs"
        title="The Term is INVALID"
      >
        <template #title v-if="err_msgs.length === 0">
          <span class="font-semibold">The Term is VALID</span>
        </template>
      </error-banner>
      <div class="grid grid-cols-4 gap-3">
        <div>
          <label for="term_start_date" class="font-semibold">Start Date</label>
        </div>
        <div>
          <input
            type="date"
            id="term_start_date"
            class="w-full h-8 rounded border-grey"
            v-model="start_date"
          />
        </div>
        <div>
          <label for="term_edit_name" class="font-semibold">Term Name</label>
        </div>
        <div>
          <input
            type="text"
            id="term_edit_name"
            class="w-full h-8 rounded border-grey"
            v-model="name"
          />
        </div>
        <div>
          <label for="term_end_date" class="font-semibold">End Date</label>
        </div>
        <div>
          <input
            type="date"
            id="term_end_date"
            class="w-full h-8 rounded border-grey"
            v-model="end_date"
          />
        </div>
        <div class="col-span-4 mb-3">
          <h3 class="thirdhead-text">Milestones for Term {{ name }}</h3>
        </div>
        <div class="col-span-2">
          <h3 class="thirdhead-text mb-2">During the Semester</h3>
          <div
            v-for="idx in Object.keys(milestones)"
            :key="`milestone-${idx}`"
            class="grid grid-cols-2 gap-3"
          >
            <div
              v-if="
                idx !== 'milestone_id' &&
                idx !== 'mentor_survey_due_date' &&
                idx !== 'pairing_starts_date' &&
                idx !== 'site_available_date' &&
                idx !== 'new_student_site_available_date'
              "
              class="mb-4 w-full"
            >
              <label class="font-semibold">{{
                idx
                  .split("_")
                  .map(
                    (word) =>
                      `${word.charAt(0).toUpperCase()}${word.substring(1)}`
                  )
                  .join(" ")
              }}</label>
            </div>
            <div
              v-if="
                idx !== 'milestone_id' &&
                idx !== 'mentor_survey_due_date' &&
                idx !== 'pairing_starts_date' &&
                idx !== 'site_available_date' &&
                idx !== 'new_student_site_available_date'
              "
              class="mb-4 w-full"
            >
              <input
                type="date"
                class="h-8 rounded border-grey w-full"
                v-model="milestones[idx]"
              />
            </div>
          </div>
        </div>
        <div class="col-span-2">
          <h3 class="thirdhead-text mb-2">Before Semester Starts</h3>
          <div
            v-for="idx in Object.keys(milestones)"
            :key="`milestone-${idx}`"
            class="grid grid-cols-2 gap-3"
          >
            <div
              v-if="
                idx === 'pairing_starts_date' ||
                idx === 'site_available_date' ||
                idx === 'new_student_site_available_date'
              "
              class="mb-4 w-full"
            >
              <label class="font-semibold">{{
                idx
                  .split("_")
                  .map(
                    (word) =>
                      `${word.charAt(0).toUpperCase()}${word.substring(1)}`
                  )
                  .join(" ")
              }}</label>
            </div>
            <div
              v-if="
                idx === 'pairing_starts_date' ||
                idx === 'site_available_date' ||
                idx === 'new_student_site_available_date'
              "
              class="mb-4 w-full"
            >
              <input
                type="date"
                class="h-8 rounded border-grey w-full"
                v-model="milestones[idx]"
              />
            </div>
          </div>
          <h3 class="thirdhead-text mb-2">After Semester Ends</h3>
          <div
            v-for="idx in Object.keys(milestones)"
            :key="`milestone-${idx}`"
            class="grid grid-cols-2 gap-3"
          >
            <div v-if="idx === 'mentor_survey_due_date'" class="mb-4 w-full">
              <label class="font-semibold">{{
                idx
                  .split("_")
                  .map(
                    (word) =>
                      `${word.charAt(0).toUpperCase()}${word.substring(1)}`
                  )
                  .join(" ")
              }}</label>
            </div>
            <div v-if="idx === 'mentor_survey_due_date'" class="mb-4 w-full">
              <input
                type="date"
                class="h-8 rounded border-grey w-full"
                v-model="milestones[idx]"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/modal/Modal";
import ErrorBanner from "@/components/forms/ErrorBanner";
import { DateTime } from "@/composables/date_utils";

export default {
  name: "EditTermModal",
  emits: ["close", "create", "update"],
  components: {
    ErrorBanner,
    Modal,
  },
  props: {
    term: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    if (this.term) {
      this.term_id = this.term.term_id;
      this.name = this.term.name;
      this.start_date = this.formatDate(this.term.start_date);
      this.end_date = this.formatDate(this.term.end_date);
      Object.entries(this.term.milestones).forEach(([name, value]) => {
        this.milestones[name] = this.formatDate(value);
      });
    }
    this.$watch(
      (vm) => [vm.term_id, vm.name, vm.milestones],
      () => {
        this.validate();
      },
      { immediate: true, deep: true }
    );
    this.$watch(
      (vm) => [vm.start_date, vm.end_date],
      () => {
        // if (
        //   (!this.name || this.name.match(/(\d+-\d+)\b/)) &&
        //   this.start_date &&
        //   this.end_date
        // ) {
        //   this.name =
        //     new Date(this.start_date).getFullYear() +
        //     "-" +
        //     new Date(this.end_date).getFullYear();
        // }
        this.validate();
      },
      { immediate: true, deep: true }
    );
  },
  data() {
    return {
      err_msgs: [],
      validating: false,

      term_id: null,
      name: null,
      start_date: null,
      end_date: null,
      milestones: {
        milestone_id: null,
        first_contact_due_date: null,
        ppdp_due_date: null,
        ppdp_meeting_due_date: null,
        monthly_fieldwork_oct_due_date: null,
        fieldwork_mid_year_due_date: null,
        monthly_fieldwork_jan_due_date: null,
        monthly_fieldwork_feb_due_date: null,
        fieldwork_year_end_due_date: null,
        year_end_meeting_due_date: null,
        mentor_survey_due_date: null,
        pairing_starts_date: null,
        site_available_date: null,
        new_student_site_available_date: null,
      },
    };
  },
  methods: {
    formatTerm() {
      const event = this.term_id ? "update" : "create";
      console.log(event);
      this.$emit(event, {
        term_id: this.term_id,
        name: this.name,
        start_date: DateTime(this.start_date),
        end_date: DateTime(this.end_date),
        milestones: {
          milestone_id: this.milestones.milestone_id,
          first_contact_due_date: DateTime(
            this.milestones.first_contact_due_date
          ),
          ppdp_due_date: DateTime(this.milestones.ppdp_due_date),
          ppdp_meeting_due_date: DateTime(
            this.milestones.ppdp_meeting_due_date
          ),
          monthly_fieldwork_oct_due_date: DateTime(
            this.milestones.monthly_fieldwork_oct_due_date
          ),
          fieldwork_mid_year_due_date: DateTime(
            this.milestones.fieldwork_mid_year_due_date
          ),
          monthly_fieldwork_jan_due_date: DateTime(
            this.milestones.monthly_fieldwork_jan_due_date
          ),
          monthly_fieldwork_feb_due_date: DateTime(
            this.milestones.monthly_fieldwork_feb_due_date
          ),
          fieldwork_year_end_due_date: DateTime(
            this.milestones.fieldwork_year_end_due_date
          ),
          year_end_meeting_due_date: DateTime(
            this.milestones.year_end_meeting_due_date
          ),
          mentor_survey_due_date: DateTime(
            this.milestones.mentor_survey_due_date
          ),
          pairing_starts_date: DateTime(this.milestones.pairing_starts_date),
          site_available_date: DateTime(this.milestones.site_available_date),
          new_student_site_available_date: DateTime(
            this.milestones.new_student_site_available_date
          ),
        },
      });
    },
    formatDate(date) {
      return date ? date.split(" ")[0] : null;
    },
    validate() {
      this.validating = true;
      this.err_msgs = [];
      if (!this.name) {
        this.err_msgs.push("Name is required");
      }
      if (!this.start_date) {
        this.err_msgs.push("Start Date is required");
      }
      if (!this.end_date) {
        this.err_msgs.push("End Date is required");
      }
      if (new Date(this.start_date) - new Date(this.end_date) > 0) {
        this.err_msgs.push("End Date must be after Start Date");
      }
      Object.entries(this.milestones).forEach(([name, value]) => {
        if (name !== "milestone_id") {
          const field_name = name
            .split("_")
            .map(
              (word) => `${word.charAt(0).toUpperCase()}${word.substring(1)}`
            )
            .join(" ");
          if (!value) {
            this.err_msgs.push(`${field_name} is required`);
          } else if (
            (new Date(value) - new Date(this.start_date) < 0 ||
              new Date(this.end_date) - new Date(value) < 0) &&
            name !== "mentor_survey_due_date"
          ) {
            this.err_msgs.push(
              `${field_name} must be between Start Date and End Date`
            );
          }
        }
      });
      this.validating = false;
    },
  },
};
</script>

<style scoped></style>
